document.onmousemove = function (e) {mousePos(e);};

var mouseX = 0;
var mouseY = 0;
var initialLoveTop;

function mousePos (e) {
    mouseX = e.pageX - window.pageXOffset;
    mouseY = e.pageY - window.pageYOffset;

    mappedX = mouseX / window.innerWidth - 0.2;
    mappedY = mouseY / window.innerHeight - 0.5;

    document.documentElement.style.setProperty('--mapped-x', mappedX);
    document.documentElement.style.setProperty('--mapped-y', -mappedY);

    return true;
}

window.onload = loadIntro();
function loadIntro() {
  loadPart('1', 1000);
  loadPart('2', 2000);
  loadPart('3', 4000);
  // setTimeout(function() {
  //   initialLoveTop = getElemDistance(document.querySelector('.why-i-design'));
  // }, 1000);
}

window.addEventListener('DOMContentLoaded', (event) => {
  initialLoveTop = getElemDistance(document.querySelector('.why-i-design'));
  hideConversation();
  console.log('loaded');
});


document.addEventListener("scroll", loadConv);
window.addEventListener("resize", loadConv);
window.addEventListener("orientationchange", loadConv);
function loadConv() {
  loadPart('3', 500);
}

function loadPart(no, delay) {
  setTimeout(function() {
    var delayList = document.querySelectorAll('.delayed--' + no);
    delayList.forEach(function(item) {
      item.classList.remove('delayed');
    });
  }, delay);
}

function hideConversation() {
  let conversationList = document.querySelectorAll('.conversation, .why-i-design');
  conversationList.forEach(function(item) {
    item.classList.add('hidden');
  });

  let buttonList = document.querySelectorAll('.button');
  buttonList.forEach(function(butt) {
    butt.onclick = function() { loadNext(this) };
  });

}

function loadNext(butt) {
  let targ = butt.closest('section').nextElementSibling;
  targ.classList.remove('hidden');
  window.scrollTo(0, getElemDistance(targ))
}

window.onscroll = updateScrollPos;

function updateScrollPos() {
  let lover1 = document.querySelector('#love-level-1'),
    scrollPos1 = window.pageYOffset - initialLoveTop,
    relativeScrollPos1 = scrollPos1 / window.innerHeight * 100;

  if(relativeScrollPos1 > 0) {
    lover1.style.setProperty('top', 0);
  } else {
    lover1.style.setProperty('top', '70vh');
  }

  let lover2 = document.querySelector('#love-level-2'),
    scrollPos2 = window.pageYOffset - initialLoveTop,
    relativeScrollPos2 = scrollPos2 / window.innerHeight * 100 - 77.5;

  if(relativeScrollPos2 > 0) {
    lover2.style.setProperty('top', 0);
    lover2.style.setProperty('margin-top', '-17.5vh');
  } else {
    lover2.style.setProperty('top', '77.5vh');
    lover2.style.setProperty('margin-top', '-92.5vh');
  }

  let lover3 = document.querySelector('#love-level-3'),
    scrollPos3 = window.pageYOffset - initialLoveTop,
    relativeScrollPos3 = scrollPos3 / window.innerHeight * 100 - 162.5;

  if(relativeScrollPos3 > 0) {
    lover3.style.setProperty('top', 0);
    lover3.style.setProperty('margin-top', '-6vh');
  } else {
    lover3.style.setProperty('top', '85vh');
    lover3.style.setProperty('margin-top', '-92.5vh');
  }
}

function getElemDistance(elem) {
  var location = 0;
  if (elem.offsetParent) {
    do {
      location += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
  }
  return location >= 0 ? location : 0;
}
